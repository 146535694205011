<template>
  <div class="hello">
     <div class="car-card-pay">
       <div class="car-card-bg">
       </div>
       <div class="car-card">
         <div class="car-img">
           <img :src="item.placeMainImage" @error="nofind"/>
         </div>
         <div class="car-info">
           <div class="car-title">{{item.placeName}}</div>
           <div class="car-star">
             <div class="car-brand">{{item.companyName}}</div>
             <div class="car-star-bg" v-if="false">
               <van-rate size="10" v-model="rateValue" color="#F96501"/>
             </div>
           </div>
           <div class="car-call" v-for="(_item, _index) in item.placeDescribe.split('、')" :key="_item" style="margin-bottom: 5px;'">
             <div class="car-phone"><a :href="'tel:' + _item">{{_item}}</a></div>
           </div>
           <div class="car-address">{{item.address}}</div>
         </div>
       </div>
       <div class="order-pay-panel">
        <div v-if="paymentStatus==0&&ordreStatus==0" :class="amount>0?'car-pay car-pay-success':'car-pay car-pay-f'" @click="goPay">去核销</div>
        <div v-else class="car-pay car-pay-success">
          <div v-if="paymentStatus==2">核销成功</div>
          <div v-if="paymentStatus==0&&ordreStatus==0">待核销</div>
          <div v-if="paymentStatus==0&&ordreStatus==3">已取消</div>
          <div v-if="paymentStatus==4">已退还</div>
        </div>
      </div>
      <div class="order-bottom" v-if="paymentStatus==2||paymentStatus==3||paymentStatus==4">
        <div class="order-bottom-title">优惠说明</div>
        <div class="order-bottom-discount">
          <div>{{promotionType}}</div>
          <div>¥{{discount}}元</div>
        </div>
        <div class="order-bottom-tips">
          {{promotion}}
        </div>
      </div>
      <div class="order-info">
        <div class="order-bottom-title">订单信息</div>
        <div style="margin-bottom:5px;margin-top: 5px;">订单编号：{{sn}}</div>
        <div style="margin-bottom:5px;">下单时间：{{createDate}}</div>
        <div style="margin-bottom:5px;">订单金额：<font>¥{{amount}}元</font></div>
        <div v-if="paymentStatus==2||paymentStatus==4" style="margin-bottom:5px;">优惠金额：<font>¥{{discount}}元</font></div>
        <div v-if="paymentStatus==2||paymentStatus==4">实付金额：<font>¥{{realpay}}元</font></div>
      </div>
     </div>
  </div>
</template>

<script>
import { Dialog } from 'vant-green';
import { NumberKeyboard } from 'vant-green';
import { findPlaceById,getOrder } from '@/api/apis.js'
export default {
  name: 'OrderDetail',
  data() {
    return {
      rateValue:5,
      item:{placeDescribe:''},
      paymentStatus:'',
      ordreStatus:'',
      sn:'',
      createDate:'',
      amount:'',
      realpay:'',
      sn:this.$route.query.sn || '',
      ccbParamSJ:this.$route.query.ccbParamSJ || '',
      params:'',
      bname:'',
      placeId:'',
      promotion:'',
      discount:0,
      promotionType:'',
    };
  },
  mounted () {
    this.checkOS();
    this.getOrderInfo();
  },
  methods: {
    nofind(e) {
      e.target.src = 'https://img.tglmall.com.cn/wd/000.png';
    },
    loadShopData() {
      let that = this;
      findPlaceById(this.placeId).then(res => {
        if (res.data.code == 200) {
          this.item = res.data.data;
          let hw = 100//this.getDistance(this.lat, this.lng, this.item.latitude, this.item.longitude);
          this.item.distanceNum = hw;
          this.item.distance = hw +"米";
          if (hw && hw !== -1 && hw > 1000) { //拿到正确的值
              //转换成公里
              hw = (hw / 2 / 500).toFixed(2) + 'km'
              this.item.distance = hw;
          } else {
              this.item.distance = "100米内";
          }
        }
      }).catch(err => { console.log('获取类别失败', err); })
    },
    checkOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      this.isAndroid = /android/i.test(userAgent);
    },
    getOrderInfo() {
      let that = this;
      getOrder(this.sn).then(res => {
        if (res.data.code == 200) {
          localStorage.setItem('token', res.data.token);
          that.paymentStatus = res.data.paymentStatus;
          that.ordreStatus = res.data.ordreStatus;
          that.amount = res.data.amount;
          that.sn = res.data.sn;
          that.createDate = res.data.createDate;
          that.bname = res.data.bname;
          that.params = res.data.params;
          that.placeId = res.data.placeId;
          that.promotion = res.data.promotion;
          that.discount = res.data.discount;
          that.realpay = res.data.realpay;
          that.promotionType = res.data.promotionType;
          that.loadShopData();
        } else {
          this.$router.push({path:'/', query: {ccbParamSJ:that.ccbParamSJ}});
        }
      }).catch(err => { console.log('获取类别失败', err); })
    },
    goPay() {
      let that = this;
      if (that.paymentStatus != 2) {
        if (that.isIOS) {
          that.MBS_DIRECT_PAY_IOS(that.params);
        } else {
          that.MBS_DIRECT_PAY_ANDROID(that.params);
        }
      } else {
        Dialog({ message: '订单已核销成功' });
      }
    },
    //IPHONE调起收银台
    MBS_DIRECT_PAY_IOS(payInfo){
        window.location="mbspay://direct?"+ payInfo;
    },
    //安卓调起收银台
    MBS_DIRECT_PAY_ANDROID(payInfo){
        window.mbspay.directpay(payInfo);
    },
    // 返回上一页
    onClickLeft() {
      this.$router.push({path:'/', query: {ccbParamSJ:this.ccbParamSJ}});
    },
    goIndex() {
      this.$router.push({path:'/newIndex', query: {ccbParamSJ:this.ccbParamSJ}});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.car-flag {
  width: 30px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #25282A;
  line-height: 22px;
  text-align: center;
}
.order-pay-panel {
  margin: 10px auto;
  padding: 20px;
  background: #fff;
}
.order-bottom {
  margin: 10px 0px;
  background: #fff;
  padding: 10px 20px 20px 20px;
  text-align: left;

}
.order-bottom-title {
  font-weight:bold;
  font-size:16px;
  color: #2c3e50;
}
.order-bottom-tips {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
}
.order-bottom-discount {
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
  font-size: 12px;
  color:#606060;
}
.order-tips {
  font-size: 14px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.car-pay-panel {
  width: 90%;
  margin: 20px auto;
  height: 44px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 2px solid #F96501;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.car-pay-panel input {
  width: 95%;
  height: 39px;
  border: none;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
}
.car-card-pay {
  height: 93vh;
  width: 100%;
  padding: 0px 0px 20px 0px;
}
.car-card-bg {
  background: linear-gradient(270deg, #FBA149 0%, #FE6402 100%);
  height: 110px;
  with:100%;
  margin: 0px;
  padding: 0px;
}
.bg-img {
  height: 307px;
  width: 100%;
}
.hello {
  background: #F6F7F8;
  position: relative;
}
.van-rate {
  margin-top:2px;
  margin-right:5px;
}
.car-new-panel {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.car-new-search {
  width: 355px;
  height: 126px;
}
.car-new-search img {
  width: 355px;
  height: 126px;
}
.car-card {
  margin: -90px auto auto auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  width: 95%;
  min-height: 90px;
  background: #FFFFFF;
  border-radius: 16px;
}
.car-img {
  width: 84px;
  height: 84px;
  background: #D8D8D8;
  border-radius: 8px;
}
.car-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.car-info {
  text-align: left;
  font-size: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
}
.car-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 13px;
  color: #25282A;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.car-call {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:3px 0px;
}
.car-phone {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-phone.png-6142) no-repeat;
  height: 17px;
  width: 111px;
  line-height:17px;
  background-size: 111px 17px;
  display: flex;
  justify-content: flex-end;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #607088;
  padding-right:3px;
}
.car-phone a {
  font-size: 12px;
  color: #607088;
  text-decoration: none; /* 去掉下划线 */
}
.car-star {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0px 0px 3px 0px;
}
.car-star-bg {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-review.png-6143) no-repeat;
  height: 17px;
  width: 111px;
  background-size: 111px 17px;
  display: flex;
  justify-content: flex-end;
}
.car-location {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.car-address {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(37,40,42,0.6);
  line-height: 17px;
  text-align: left;
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-location.png-6142) left 3px no-repeat;
  background-size: 10px 10px;
  padding-left:15px;
  margin:3px 0px 3px 0px;
}
.car-distance {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 12px;
  color: #25282A;
  text-align: center;
  font-style: normal;
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-distance.png-6139) left center no-repeat;
  background-size: 8px 8px;
  padding-left:12px;
}
.car-pay {
  width: 100%;
  height: 38px;
  background: linear-gradient( 270deg, #FBA149 0%, #FE6402 100%);
  border-radius: 5px;
  margin:0px auto;
  line-height:38px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
}
.car-pay-f {
  opacity: 0.4;
}
.car-pay-success {
  opacity: 1;
}
.car-find {
  margin: 15px;
  border: 2px solid #F96501;
  border-radius: 5px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
  position: absolute;
  width: 325px;
  bottom: 0px;
}
.car-find input {
  width: 80%;
  height: 80%;
  border: none;
  outline: none;
  font-size: 12px;
}
.car-search {
  width: 80px;
  height: 28px;
  background: linear-gradient( 270deg, #FBA149 0%, #FE6402 100%);
  border-radius: 6px;
  font-size: 12px;
  color: #FFFFFF;
  line-height:28px;
}
.autoComplete {
  width: calc(100% - 30px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0px 15px;
  border-radius: 5px;
  text-align: left;
  position: absolute;
  z-index: 999;
  background: #fff;
}
.autoComplete-item {
  margin: 0px 5px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.autoComplete-item:last-child {
    border-bottom: none;
}
.order-info {
  padding: 10px 20px;
  text-align: left;
  background: #fff;
  margin: 10px auto;
  font-size: 12px;
  color: #606060;
}
.car-brand {
  color: rgba(37, 40, 42, 0.6);
}
</style>
